import React, { useEffect } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'
// 重定向
function Redirect({ to }) {
    let navigate = useNavigate();
    useEffect(() => {
        navigate(to);
    });
    return null;
}

const Roudes = (props) => {
    // 解刨数据
    // 组件
    let component = props.roudes.filter((item) => item.component)
    // 重定向
    let Redirects = props.roudes.filter((item) => item.Redirect)
    console.log(props);
    return <Routes>
        {
            component.map((item, index) => {
                return <Route key={index} path={item.path} element={<item.component></item.component>} />
            })
        }
        {
            // 设定重定向
            Redirects.map((item, index) => {
                // return <Redirect to={item.Redirect} from={item.path} key={index}></Redirect>
                return <Route
                    path={item.path}
                    element={<Redirect to={item.Redirect} />}
                    key={index}
                />
            })
        }
    </Routes>
}

export default Roudes


