// 第七模块
import React, { Component } from 'react'

export default class container7 extends Component {
    render() {
        return (
            <div className='container7'>
                <div className="title">
                    Specifications
                </div>
                <div className="content">
                    <div>
                        <p>Model</p>
                        <p>Color</p>
                        <p>Bluetooth</p>
                        <p>Battery</p>
                        <p>Charging Method</p>
                        <p>Current Draw</p>
                        <p>Battery Life</p>
                        <p>Size</p>
                        <p>Weight</p>
                        <p>Accessories</p>
                        <p></p>
                        <p>MIDI Interface</p>
                        <p>Octaves</p>
                        <p>Connectors</p>
                    </div>
                    <div>
                        <p>R1</p>
                        <p>Matte black</p>
                        <p>BLE MIDI</p>
                        <p>2600mAh</p>
                        <p>USB {'('}Type-C{')'}</p>
                        <p>1.5A</p>
                        <p>6 hours lights on, 10 hours lights off</p>
                        <p> 67cm {'('}length{")"} × 4cm {'('}width{")"} × 5cm {'('}height{")"}</p>
                        <p>450g</p>
                        <p>Type-C cable, user's manual, Quickstart Guide,</p>
                        <p>neck strap, mouthpiece cap, dust bag</p>
                        <p>USB MIDI, BLE MIDI</p>
                        <p>-1, 0, +1, +2</p>
                        <p> 3.5mm TRS Headphone output, 6.35mm TRS audio output,</p>
                        <p>Type-C port {'('}charging and USB MIDI supported{')'}</p>
                    </div>
                    <div>
                        <img src={React.$img.Group5} alt="" />
                    </div>
                </div>
                <div className='container7buttomImgDom' id='container7buttomImgDom'>
                    <div className='buttomTitle' >
                        <p className='topTitle'>
                            Can't Wait to Play?
                        </p>

                        <p className="title">
                            Join Our KOL Program
                        </p>
                        <p className='content'>
                            If you are a relevant KOL, Youtuber, or any kind of influencer, please drop an email
                            <br />
                            to social{"("}at{")"}robkoo.com!
                        </p>
                    </div>
                    <img src={React.$img.container7buttomImg} alt="" className='container7buttomImg' />
                </div>
            </div>
        )
    }
}
