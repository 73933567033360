// 页面第一模块
import React, { Component } from 'react'
import { CloseCircleOutlined } from '@ant-design/icons';
export default class Container1 extends Component {
    render() {
        return (
            <div className="container1">
                <div className="containerLeft" ref={this.leftDom} >
                    <p>Wind Synthesizer R1</p>
                    <p>Musical Instrument</p>
                    {/*<p> </p>*/}
                    <p>From the Future            </p>
                    <p>Learn in minutes and gig in 2 weeks, bring light to <br /> yourself and your audience.</p>
                    <p onClick={() => this.videoOption()}>
                        <span className='play' > <img src={React.$img.Play} alt="" /></span>
                        See Trailer
                    </p>
                    <p onClick={() => this.orderOption()}>
                        <img src={React.$img.store_button} alt="" />
                        Buy Now
                    </p>
                </div>
                <div className="videoDom" style={this.state.videoPosition}>
                    <CloseCircleOutlined className='icon' onClick={() => this.videoOption(false)} />
                    <video controls src="https://video.robkoo.com/e20eab87874c457c89f1ed3d395bd9dc/1237dbfffa5146bdb4c8f82449cbfcbb-df9e953dab1edbe413d1fce64295b7fb-sd.mp4" ref={this.videoDom}></video>
                </div>
                <div className="containerRight" ref={this.RightDom}>

                </div>
                
                <img src={React.$img.ClarinetImg} alt="" className='ClarinetImg' />
                <p className='scrollForMore'>Scroll for more</p>
                <div className='clickTemplate'>
                    <div ref={this.leftCliDom}>
                        <div className="paly" onClick={() => this.videoOption()}></div>
                    </div>
                    <div>
                        <div className={'order_buy'} onClick={() => this.orderOption()}></div>
                    </div>
                </div>
            </div>
        )
    }
    constructor(props) {
        super(props);
        this.state = {
            ClarinetImgOption: '',
            videoPosition: {}
        };
        // 视频播放器控件
        this.videoDom = React.createRef();
        // 左边dom
        this.leftDom = React.createRef();
        this.leftCliDom = React.createRef();
        // 右边dom
        this.RightDom = React.createRef();
        // this.RightCliDom = React.createRef();
    }

    // 播放視頻
    videoOption(flug = true) {
        let videoDom = this.videoDom.current;
        if (flug) {
            videoDom.play();//开始播放
            this.setState({
                videoPosition: {
                    // display: 'block',
                    top: "50%",
                    left: "50%",
                    transform: " translate(-50%, -50%)",
                    width: "90%",
                    height:'90%'
                }
            })
        } else {
            videoDom.pause(); //暂停控制
            this.setState({
                videoPosition: {
                    width: "0PX",
                    left: "123px",
                    top: "601.55px",
                    height:'0%'

                }
            })

        }
    }
    orderOption() {
        window.open('https://store.robkoo.com/products/buy_r1')
    }
    componentDidMount() {
        //   第一模块竖笛 监听滚动条
        window.addEventListener('scroll', (event) => this.clarinetImgScroll(event))
        // 监听鼠标动向
        this.monitorMouse()
    }
    // 监听鼠标动向
    monitorMouse() {
        let leftDom = this.leftDom.current;
        let leftCliDom = this.leftCliDom.current;
        let RightDom = this.RightDom.current;
        // let RightCliDom = this.RightCliDom.current;
        leftCliDom.addEventListener('mouseenter', () => {
            leftDom.style.transform = 'scale(1.05)'
        })
        leftCliDom.addEventListener('mouseleave', () => {
            leftDom.style.transform = 'scale(1)'
        })
        // RightCliDom.addEventListener('mouseenter', () => {
        //     RightDom.style.transform = 'scale(1.05)'
        // })
        // RightCliDom.addEventListener('mouseleave', () => {
        //     RightDom.style.transform = 'scale(1)'
        // })

    }
    //   第一模块竖笛 监听滚动条
    clarinetImgScroll(event) {
        const scrollTop = (event.srcElement ? event.srcElement.documentElement.scrollTop : false)
            || window.pageYOffset
            || (event.srcElement ? event.srcElement.body.scrollTop : 0);
        if (scrollTop > 0) {
            this.setState({
                padding: '5px'
            })
        } else {
            this.setState({
                padding: '20px'
            })
        }
    }
}
