
/*
 * @作者: 王朗
 * @Date: 2021-10-12 11:25:36
 * @描述: 获取页面
 */
//require.context()

// 1. 一个要搜索的目录，
// 2. 一个标记表示是否还要搜索其子目录， 
// 3. 一个匹配文件的正则表达式。
const homeConfig = (startPath, guolv = []) => {
    let context = require.context("../views", true, /\index.js$/);
    let paths = context.keys()//获取了所有文件的路径

    let routes = paths.map(path => {
        //批量获取引入的组件
        let component = context(path).default;
        // console.log(component);
        //组件扩展属性方便渲染菜单
        let meta = component['meta'] || {}
        //console.log(path)
        //这个正则的目的
        //因为地址是./Discover/Djradio/index.js这种类型的并不能直接使用,所以要进行处理
        //1.接去掉最前的"." 得到的结果是/Discover/Djradio/index.js
        //2.处理了还是不能直接用 因为我们的预期/Discover/Djradio,所以通过正则将index.js干掉了
        //3.有可能后面的路径不是文件夹 得到的结果是/Discover/abc.js,后缀名并不能用到路由配置的path属性中,所以.js后缀名又用正则替换掉
        path = path.substr(1).replace(/(\/index\.js|\.js)$/, "")
        return {
            path,
            component,
            meta
        }
    });
    // 过滤路由
    function diagnosis(routes) {
        let newRoutes = [];
        routes.forEach(i => {
            let flug = true;
            guolv.forEach(t => {
                if (i.path == t) {
                    flug = false;
                }
            })
            if (flug) {
                newRoutes.push(i)
            }
        })
        newRoutes.forEach(item => {
            item.path = startPath ? startPath + item.path : item.path
        })
        return newRoutes
    }

    function convertTree(routes) {
        routes = diagnosis(routes);
        let treeArr = [];
        //1.处理数据 将每条数据的id和parent处理好 (俗称 爸爸去哪儿了)
        routes.forEach(route => {
            let comparePaths = route.path.substr(1).split("/")
            // console.log(comparePaths)
            if (comparePaths.length === 1) {
                //说明是根节点,根节点不需要添加parent_id
                route.id = comparePaths.join("")
            } else {
                //说明具有父节点
                //先处理自己的id
                route.id = comparePaths.join("");
                //comparePaths除去最后一项就是parent_id
                comparePaths.pop()
                route.parent_id = comparePaths.join("")
            }
        })
        //2.所有的数据都已经找到了父节点的id,下面才是真正的找父节点了
        routes.forEach(route => {
            //判断当前的route有没有parent_id
            if (route.parent_id) {
                //有父节点
                //id===parent_id的那个route就是当前route的父节点
                let target = routes.find(v => { return v.id === route.parent_id });
                //判断父节点有没有children这个属性
                if (!target.children) {
                    target.children = []
                }
                route.path = startPath + route.path;
                target.children.push(route)
            } else {
                route.path = startPath + route.path;
                treeArr.push(route)
            }
        })

        return treeArr
    }
    return diagnosis(routes)
}
export default homeConfig;