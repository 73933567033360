// 第六模块
import React, { Component } from 'react'

export default class container6 extends Component {
    render() {
        return (
            <div className='container6'>
                <div className='container6Dom'>
                    <p className="title">
                        Access Full Experience
                    </p>
                    <p className='content'>
                        Made by Robkoo team, the beloved performance App, JamKoo, offers full
                        <br />
                        support for R1.
                    </p>
                    <img src={React.$img.container6Image1} alt="" className='container6Image1' />
                </div>
                <div className='container6Dom'>
                    <p className="title">
                        Tweak All Settings
                    </p>
                    <p className='content'>
                        When R1 is awake and nearby, it will be detected by JamKoo automatically.
                        <br />
                        Connect wirelessly and you have access to all settings of R1 on a much
                        <br />
                        larger screen, on top of a dedicated dummy-proof course.
                    </p>
                    <img src={React.$img.container6Image2} alt="" />
                </div>
                <div className='container6Dom'>
                    <p className="title">
                        User Patch Bank
                    </p>
                    <p className='content'>
                        Create your own list of patches. Switch between any sound, key, and octave
                        <br />
                        in a flash for your stage performance.
                    </p>
                    <img src={React.$img.container6Image3} alt="" />
                </div>
                <div className='container6Dom'>
                    <p className="title">
                        Add Fingerings
                    </p>
                    <p className='content'>
                        Save up to 20 custom fingerings. Nothing will get into your way of creating
                        <br />
                        melody.
                    </p>
                    <img src={React.$img.container6Image4} alt="" />
                </div>
                <div className='container6Dom'>
                    <p className="title">
                        Create Your Own Lights
                    </p>
                    <p className='content'>
                        Take full control over the light patterns. Pick any color you want for both
                        <br />
                        standby mode and breath mode.
                    </p>
                    <img src={React.$img.container6Image51} alt="" className='container6Image51' />
                    <img src={React.$img.container6Image52} alt="" className='container6Image52' />
                </div>
                <div className='container6Dom lastContainer6Dom'>
                    <p className="title">
                        An Expressive Wind Controller
                    </p>
                    <p className='content'>
                        Wirelessly or cabled, while connected to an applicable device, R1 is a
                        <br />
                        qualified MIDI controller that sends more than notes, but also breath control,
                        <br />
                        portamento, program change, and so on.
                    </p>
                </div>

                <div className='card'>
                    <img src={React.$img.container6Image6} alt="" className='bgImg' />

                    <img src={React.$img.container6Bg6} alt="" className='cardImg' />
                </div>
            </div>
        )
    }
}
