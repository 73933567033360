import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/style/reset.scss';//样式重置文档
import Router from './router/index' // 引入写好的router入口
import routerConfig from './router/routerConfig'// 引入写好的数据
import { HashRouter } from 'react-router-dom'// 引入 router的框架
import ImageUrl from './assets/imgUrl';// 引入所有图片  https://blog.csdn.net/weixin_45760365/article/details/120272751?spm=1001.2014.3001.5501
import 'antd/dist/antd.css';
import './lib/i18n/config'; // 引用配置文件
import './lib/rem.js'
import App from './views/wind-synthesizer-r1';
React.$img = ImageUrl;//全局使用

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App></App>
    {/* <HashRouter>
      <Router roudes={routerConfig}></Router>
    </HashRouter> */}
  </React.StrictMode>
);

