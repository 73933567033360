// 页尾元素
import React, { Component } from 'react'

export default class FooterDom extends Component {
    render() {
        return (
            <div className='FooterDom'>
                <div className="content">
                    <div>
                        <div className='svg'></div>
                        <p>
                            Digital art crafting,
                            <br />
                            sharing and learning
                            <br />
                            made easy.
                        </p>
                    </div>
                    <div>
                        <p className="title">
                            Apps
                        </p>
                        <div className="content">
                            <p onClick={() => this.openUrl('http://www.robkoo.com/zh/jamkoo')} style={{ cursor: "pointer" }}>JamKoo</p>
                            <p onClick={() => this.openUrl('https://apps.apple.com/cn/app/摇响/id1556841005')} style={{ cursor: "pointer" }}>Swaying Tune</p>
                            <p></p>
                        </div>
                    </div>
                    <div className='ContactUsDom'>
                        <p className="title">
                            Contact Us
                        </p>
                        <div className="content">
                            <p className='title1'>HQ Office</p>
                            <p className='txt'>
                                B109, DoBe E-Manor of Qibao, No. 6
                                <br />
                                Huazhong Road, Minhang District,
                                <br />
                                Shanghai, China.</p>
                            <p className='title1'>Shenzhen Office</p>
                            <p className='txt'>
                                Room 504, Building A, Minqi Tech park,
                                <br />
                                No. 65 Lishan Road, Taoyuan street,
                                <br />
                                Nanshan District, Shenzhen, China.
                            </p>
                        </div>
                    </div>
                    <div>

                    </div>
                    <div className='trademark'>
                        <div>
                            <img className='image' src={React.$img.footerDom1} alt="" onClick={() => this.openUrl('https://www.instagram.com/robkoo_official/')} />
                            <img className='image' src={React.$img.footerDom2} alt="" onClick={() => this.openUrl('https://www.facebook.com/RobkooMusic')} />
                            <img className='image' src={React.$img.footerDom3} alt="" onClick={() => this.openUrl("https://www.youtube.com/channel/UC-ARigZHHetwcreENNSTPMg")} />
                            <img className='image' src={React.$img.footerDom4} alt="" onClick={() => this.openUrl("https://twitter.com/Robkoo_Official")} />
                        </div>
                        <p>Discover our app</p>
                        <div>
                            <img src={React.$img.PlayStore} alt="" onClick={() => this.openUrl('https://apps.apple.com/app/apple-store/id1457240484')} />
                        </div>
                    </div>
                </div>
                <p className='website'>
                    All rights reserved@robkoo.com
                </p>
                <div className="luminescence" >
                </div>
            </div>
        )
    }
    openUrl(url) {
        // window.open(url, '_self      ')
        window.location.href = url
    }
}
