import React, { Component } from 'react'
import './index.scss'
import HeaderNav from '../../components/headerNav/index';// 头部导航
import Container1 from './container1' // 第一模块
import Container2 from './container2' // 第二模块
import Container3 from './container3' // 第三模块
import Container4 from './container4' // 第四模块
import Container5 from './container5' // 第五模块
import Container6 from './container6' // 第六模块
import Container7 from './container7' // 第七模块
import FooterDom from './footerDom';// 页尾元素
import ContainerMini from './containerMini';
export default class App extends Component {
  render() {
    return (
      <div className='home'>
        <HeaderNav />
        <Container1 />
        <ContainerMini />
        <Container2 />
        <Container3 />
        <Container4 />
        <Container5 />
        <Container6 />
        <Container7 />
        <FooterDom />
      </div>
    )
  }
}
