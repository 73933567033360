import React, { Component } from 'react'

export default class my extends Component {
    render() {
        return (
            <div>
                my
            </div>
        )
    }
}
