// 第五模块
import React, { Component } from 'react'
export default class Container5 extends Component {
    render() {
        return (
            <div className='container5'>
                <p className="title">
                    True Colour of Music
                </p>
                <p className='content'>
                    The double cyber-style RGB lights respond to your play, and glow over
                    <br />
                    60,000 colors of your choice. Customize in JamKoo App.
                </p>
                <div className='imgContent'>
                    <img src={React.$img.mokuai5Sd1} alt="" />
                </div>
                <p className='title'>
                    Shakes and Waves
                </p>
                <p className='content'>
                    Excited? Use motion control to spice up your tune! The built-in gyroscope
                    <br />
                    lets you pitch bend, vibrato, and make creative crafts by simply lifting up.
                </p>
                <div className='imgContent2'>
                    <img src={React.$img.mokuai5Sd2} alt="" />
                </div>
                <p className='title'>
                    Play Horizontal
                </p>
                <p className='content'>
                    The only wind controller that can be played both vertically and
                    <br />
                    horizontally. Try out the new horizontal mouthpiece, you'll get a
                    <br />
                    cyber flute!
                </p>
                <div className='mokuai5Sd9'>
                    <img src={React.$img.mokuai5Sd9} alt="" />
                </div>
                <p className='container5R1Title'>
                    R1 is equipped with a variety
                    <br />
                    of high-quality parts:
                </p>

                <div className='imgContent3'>
                    <div>
                        <p className='title'>
                            Food Grade Mouthpiece
                        </p>
                        <p className='content'>
                            Same material as baby pacifier for
                            <br />
                            utmost health  protection, with 3
                            <br />
                            mouthpiece types available.
                        </p>
                    </div>
                    <img src={React.$img.mokuai5Sd3} alt="" />
                </div>
                <div className='imgContent4'>
                    <img src={React.$img.mokuai5Sd4} alt="" />
                    <div>
                        <p className='title'>
                            Touch-sensing
                            <br />
                            Performance Keys
                        </p>
                        <p className='content'>
                            Perform by touching the keys.
                            <br />
                            Minimal noise and maximum durability.
                        </p>
                    </div>
                </div>
                <div className='imgContent3'>
                    <div>
                        <p className='title'>
                            OLED Screen
                        </p>
                        <p className='content'>
                            A handy screen that shows useful status
                            <br />
                            and helps you navigate even in the dark.
                        </p>
                    </div>
                    <img src={React.$img.mokuai5Sd5} alt="" />
                </div>
                <div className='imgContent6'>
                    <img src={React.$img.mokuai5Sd6} alt="" className='mokuai5Sd6' />
                    <div>
                        <p className='title'>
                            Patent-protected
                            <br />
                            RGB Lights
                        </p>
                        <p className='content'>
                            Gives you and your audience real-
                            <br />
                            time reflection of your breath,
                            <br />
                            in any color you want.

                        </p>
                    </div>
                </div>
                <a className='mokuai5Sd8'>
                    <img src={React.$img.mokuai5Sd8} alt="" className='mokuai5Sd8' />
                </a>
            </div>
        )
    }
}
