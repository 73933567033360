//插在第一个container跟第二个container之间
import React, { Component } from 'react'
import MiniIntro from '../../assets/mp4/MiniIntro.mp4'

export default class containerMini extends Component {
    render() {
        return (
            <div className='containerMini'>
                <div className='miniIntro' onClick={() => this.clickShowMore()}>
                    <video src={MiniIntro} muted="muted" autoplay="autoplay" playsinline="true" webkit-playsinline="true" loop></video>
                    <div className="wordsContainer">
                        <p>2023 New Model</p>
                        {/* <p>Clarii mini</p> */}
                        <p>Breathe. Shake. Play.</p>
                        <p>A Digital Wind Instrument & MIDI Controller.<br />Everyone Can Play in 2 Minutes.</p>
                        <p>Learn more</p>
                        <img className='clariiImg' src={React.$img.clariimini}></img>
                        <img className='learnMoreBg' src={React.$img.learnMoreBg} ></img>
                    </div>
                </div>
            </div>
        );
    }
    clickShowMore() {
        console.log('show learn more');
        window.open('https://www.robkoo.com/en/clariimini');
    }
}