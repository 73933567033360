// 页面第二模块
import React, { Component } from 'react'
import Voteback from '../../assets/mp4/Voteback.mp4'
export default class container2 extends Component {
    render() {
        return (
            <div className='container2'>
                <div className='shudi1'>
                    <img src={React.$img.shudi1} alt="" />
                    <img src={React.$img.shudi2} alt="" />
                </div>
                <img src={React.$img.Group4} alt="" className='Group4' />
                <p className='title'>Meet R1</p>
                <p className='content'>A wind synth & controller with responsive RGB lights.<br />
                    See your music glows while enjoying its tech-boosted<br />playability.
                </p>
                <div className='VoteForR1'>
                    <video src={Voteback} muted="muted" autoplay="autoplay" playsinline="true" webkit-playsinline="true" loop></video>

                    <div className="content">
                        <div className="content_right">
                            <img src={React.$img.mark} alt="" />
                            <p>Finalist</p>
                            <p>of the 38th TEC Awards</p>
                            <a href="https://www.tecawards.org/finalists">
                                <div className="link_box">
                                </div>
                                <div className="learn_more">Learn More</div>
                            </a>
                        </div>
                        <div className="content_left">
                            <img src={React.$img.midi} alt="" />
                            <p>3rd Place</p>
                            <p>of the 2022 MIDI Innovation Awards</p>
                            <a href="https://www.midi.org/innovation-awards">
                                <div className="link_box">
                                </div>
                                <div className="learn_more">Learn More</div>
                            </a>
                        </div>
                        <div className="content_right">
                            <img src={React.$img.cloud} alt="" />
                            <p>1st Place</p>
                            <p>of Music China Cloud Audience Award</p>
                            <a href="http://www.robkoo.com/en/archives/5786">
                                <div className="link_box">
                                </div>
                                <div className="learn_more">Learn More</div>
                            </a>
                        </div>
                    </div>
                    {/*<p className="title">R1 is Innovation</p>*/}
                    {/*<p className="content">*/}
                    {/*    Proud finalist of The 2022 MIDI Innovation Awards.*/}
                    {/*    <br />*/}
                    {/*    Find R1 on the awards website.*/}
                    {/*</p>*/}


                </div>
                <div className='bottomContent'>
                    <p>
                        Musical Performance Made Easy
                    </p>
                    <p>
                        A dedicated interactive course guides you through all the basics in 15 minutes.
                        <br />
                        Keep practising for 2 weeks, you will be ready to hit the stage!
                    </p>
                </div>
            </div>
        )
    }
}
