// 第三块
import React, { Component } from 'react'

export default class container3 extends Component {
    render() {
        return (
          <div>
            {/*<a href={'https://www.tecawards.org/finalists'} style={{width: '100%'}}>*/}
            {/*  <img src={React.$img.getWin} style={{width: '100%'}}/>*/}
            {/*</a>*/}
            <div className='container3'>
              <div></div>
              <div></div>

              <div></div>
            </div>
          </div>
        )
    }
}
