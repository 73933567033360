/*
 * @作者: 王朗
 * @Date: 2021-10-12 11:25:36
 * @描述: 封装获取图片
 */

// require.context();
// webpack的api，通过执行require.context()函数获取一个特定的上下文结构，主要用来实现自动化模块导入
// 参数：需要检索的目录 是否遍历文件夹 文件正则
// 获取“assets/image”下面所有图片  包括子文件内部照片   只获取png格式 如果获取其他格式可以 写为/.(png|jpg|...)$/ 即可引入其他类型图片  按需加载
const files = require.context(
    "../assets",
    true,
    /.png$/
);
// 图片库房 存放图片类型
const imageUrl = {};
// 获取到文件进行循环 path为文件路径
files.keys().forEach(path => {
    // 截取名
    const file_name = path.replace(/(.*\/)*([^.]+).*/ig, "$2");
    // 通过路径加载 文件
    const file = require(path + '');
    //  文件名为参数  文件为参数值 形成可用对象
    let object = { [file_name]: file }
    // 与库房合并
    Object.assign(imageUrl, object)
});
// 抛出我们做好的数据
export default imageUrl