// 第四模块
import React, { Component } from 'react'

export default class container4 extends Component {
    render() {
        return (
            <div className='container4'>
                <p className='title'>
                    Sound Like a Pro
                </p>
                <p className='content'>
                    With on-board tones sampled from top performers around the globe, R1
                    <br />
                    saves 10 or even 20 years of practice to achieve the vibe of a pro.
                </p>
                <img src={React.$img.container4Bg} alt="" />
                <p className='title'>
                    Sound Suppliers
                </p>
                <div className='brand'>
                    <img src={React.$img.container4Pp} alt="" />
                </div>

            </div>
        )
    }
}
