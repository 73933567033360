// 头部导航
import React, { Component } from 'react'
import './index.scss'
import { Menu, Dropdown, Space } from 'antd';
export default class HeaderNav extends Component {
    render() {
        return (
            <div className='headerNav' style={{ padding: `${this.state.padding} 5%`, background: this.state.background }}>
                <div className='svg'></div>
                <div className='nav'>
                    <Dropdown overlay={
                        <Menu
                            items={[
                                {
                                    label: <a href="http://www.robkoo.com/en/">Home</a>,
                                    key: '0',
                                },
                                {
                                    label: <a href="http://www.robkoo.com/en/jamkoo-en">JamKoo APP</a>,
                                    key: '1',
                                },
                                {
                                    // label: <a onClick={()=>this.openId('container7buttomImgDom')}>Get R1</a>,
                                    // label: <a onClick={() => this.scrollToAnchor('container7buttomImgDom')}>Get R1</a>,
                                  label: <a href={'https://store.robkoo.com/products/buy_r1'}>Get R1</a>,
                                    key: '2',
                                },
                              {
                                // label: <a onClick={()=>this.openId('container7buttomImgDom')}>Get R1</a>,
                                label: <a href={'https://store.robkoo.com/pages/resellers'}>Resellers</a>,
                                key: '5',
                              },
                              {
                                // label: <a onClick={()=>this.openId('container7buttomImgDom')}>Get R1</a>,
                                label: <a href={'https://store.robkoo.com/pages/r1-sound-demo'}>Sound demos</a>,
                                key: '6',
                              },
                              {
                                // label: <a onClick={()=>this.openId('container7buttomImgDom')}>Get R1</a>,
                                label: <a href={'https://store.robkoo.com/pages/artists'}>Artists</a>,
                                key: '7',
                              },
                              {
                                // label: <a onClick={()=>this.openId('container7buttomImgDom')}>Get R1</a>,
                                label: <a href={'https://store.robkoo.com/pages/support'}>Support</a>,
                                key: '4',
                              },
                                {
                                    label: <a href="http://www.robkoo.com/en/archives/category/blog-en">Blog</a>,
                                    key: '3',
                                },
                            ]}
                        />}
                        trigger={['click']}
                    >
                        <a onClick={(e) => e.preventDefault()}>
                            <Space>
                                <img src={React.$img.nav} alt="" />
                            </Space>
                        </a>
                    </Dropdown></div>
            </div>
        )
    }
    state = {
        padding: '20px',
        background: 'none',
    }
    componentDidMount() {
        //    监听滚动条
        window.addEventListener('scroll', (event) => this.bindHandleScroll(event))
    }
    //    监听滚动条
    bindHandleScroll(event) {
        const scrollTop = (event.srcElement ? event.srcElement.documentElement.scrollTop : false)
            || window.pageYOffset
            || (event.srcElement ? event.srcElement.body.scrollTop : 0);
        if (scrollTop > 0) {
            this.setState({
                padding: '5px',
                background: '#131212'
            })
        } else {
            this.setState({
                padding: '20px',
                background: 'none'
            })
        }
    }
    openId(ID){
        // let dom = document.getElementById(ID);
        // // window.offsetTop=dom.offsetTop
        // console.log(dom.offsetTop);
        // window.scrollTo(0,dom.offsetTop)
    }
  scrollToAnchor = (name) => {
    if (name) {
      // 找到锚点
      let anchorElement = document.getElementById(name);
      // 如果对应id的锚点存在，就跳转到锚点
      if(anchorElement) { anchorElement.scrollIntoView({block: 'start', behavior: 'smooth'}); }
    }
  }
}
